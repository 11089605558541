import { Outlet } from 'react-router-dom';

import { HomePage } from './home';
import {
    LearningMapsPage,
    SatsLearningMapsPage,
    GCSELearningMapsPage,
} from './learning-maps';
import { YearsPage } from './years';
import { LearningObjectivesPage } from './learning-objectives';
import {
    AddLearningContentPage,
    LearningContentPage,
} from './learning-content';
import { ProgrammesPage, ProgrammePage } from './programmes';
import { PlaceholderPage } from './shared';
import { AIPromptsPage } from './ai-promts';

export const routes = [
    {
        path: '/',
        element: <HomePage />,
    },
    {
        path: 'learning-objectives',
        element: <LearningObjectivesPage />,
    },
    {
        path: 'add-learning-content',
        children: [
            {
                path: ':learningObjectiveId',
                element: <AddLearningContentPage />,
            },
        ],
    },
    {
        path: 'learning-content',
        children: [
            {
                path: ':learningObjectiveId',
                element: <LearningContentPage />,
            },
        ],
    },
    {
        path: 'learning-maps',
        element: <LearningMapsPage />,
    },
    {
        path: 'sats-learning-maps',
        element: <SatsLearningMapsPage />,
    },
    {
        path: 'gcse-learning-maps',
        element: <GCSELearningMapsPage />,
    },
    {
        path: 'programmes',
        element: <Outlet />,
        children: [
            {
                index: true,
                element: <ProgrammesPage />,
            },
            {
                path: ':programmeId',
                element: <ProgrammePage />,
            },
        ],
    },
    {
        path: 'years',
        element: <YearsPage />,
    },
    {
        path: '*',
        element: <PlaceholderPage title="404" />,
    },
    {
        path: 'promts',
        element: <AIPromptsPage />,
    },
];

export const routesInfo: {
    [path: string]: {
        url?: string;
        label: string;
        menuItems?: {
            url: string;
            label: string;
        }[];
        altPath?: string;
    };
} = {
    '/': {
        url: '/',
        label: 'Home',
    },
    '/learning-maps': {
        label: 'Learning Maps',
        menuItems: [
            { url: '/learning-maps', label: 'Diagnostic' },
            { url: '/sats-learning-maps', label: 'SATs' },
            { url: '/gcse-learning-maps', label: 'GCSE' },
        ],
    },
    '/learning-objectives': {
        url: '/learning-objectives',
        label: 'Learning Objectives',
        altPath: '/learning-content',
    },
    '/programmes': {
        url: '/programmes',
        label: 'Programmes',
    },
    '/promts': {
        url: '/promts',
        label: 'Promts',
    },
    // "/mappings": {
    //   label: "Mappings",
    //   menuItems: [
    //     { url: "/uknational", label: "UK National Curriculum" },
    //     { url: "/ukwhiterose", label: "UK White Rose" },
    //   ],
    // },
    // "/manage": {
    //   label: "Manage",
    //   menuItems: [
    //     { url: "/years", label: "Years" },
    //     { url: "/ncstrands", label: "UK NC Strands" },
    //     { url: "/tslcategories", label: "TSL Categories" },
    //     { url: "/programmes", label: "Programmes" },
    //   ],
    // },
};
