import { FC } from 'react';

import { PlaceholderActionPanel } from '../shared';

interface Props {}

const AIPromptsPage: FC<Props> = () => {
    return <PlaceholderActionPanel />;

    // return (
    //     <PageLayout title="AI Content Creations">
    //         <AIPromptsContent />
    //     </PageLayout>
    // );
};

export default AIPromptsPage;
